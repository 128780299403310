import React from 'react';

export default ({ name, date, desc }) => {
    return (
        <>
            <p className="[ cv_content ]">{date}</p>
            <h3>{name}</h3>
            <p>{desc}</p>
        </>
    )
}